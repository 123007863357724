import React from "react";
import Router from "routers/index";

function App() {
  return (
    <div className="bg-white text-base text-black">
      <Router />
    </div>
  );
}

export default App;
