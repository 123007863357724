import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function ScrollToAnchor() {
  const location = useLocation();
  const lastHash = useRef("");

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      const element = document.getElementById(lastHash.current);

      if (element) {
        element.style.scrollMarginTop = "119px";
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }

      lastHash.current = "";
    }
  }, [location]);

  return null;
}

export default ScrollToAnchor;
