import { useState, useEffect, useRef } from "react";
import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "utils/constants";

interface useRequestProps {
  url: string;
  method?: "get" | "post" | "put" | "delete";
  headers?: { [key: string]: string };
  data?: any;
}

const useRequest = ({ url, method = "get", data }: useRequestProps) => {
  const [response, setResponse] = useState<AxiosResponse | null>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_AUTH}`,
  };

  const axiosConfigRef = useRef({
    method,
    url: `${API_BASE_URL}${url}`,
    headers,
    data,
  });

  useEffect(() => {
    axiosConfigRef.current = {
      method,
      url: `${API_BASE_URL}${url}`,
      headers,
      data,
    };

    const fetchData = async () => {
      try {
        const result = await axios.request(axiosConfigRef.current);
        setResponse(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, method, data]);

  return { response, error, loading };
};

export default useRequest;
