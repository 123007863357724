import React, { FC, useEffect, useRef } from "react";
import MainNav from "./MainNav";

export interface HeaderProps {
  className?: string;
  lang: string;
}

const Header: FC<HeaderProps> = ({ className = "", lang }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;

    if (currentScrollPos + windowHeight >= documentHeight - 350) {
      containerRef.current.classList.add("active");
    } else {
      containerRef.current.classList.remove("active");
    }
  };

  return (
    <div
      ref={containerRef}
      className={`header fixed top-auto w-full left-0 right-0 z-40 nc-header-bg transition-transform duration-300 ease-in-out header-control ${className}`}
    >
      <MainNav lang={lang} />
    </div>
  );
};

export default Header;
