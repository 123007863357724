import React, { FC, useEffect, useState } from "react";
import hero from "images/hero.jpg";
import heroLogo from "images/hero-unite.svg";
import useRequest from "hooks/useRequest";
import Loading from "shared/Loading/Loading";
import { CDN_BASE_URL } from "utils/constants";
import { useTranslation } from "react-i18next";

export interface SectionHeroProps {
  className?: string;
  lang: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "", lang }) => {
  const [t] = useTranslation("global");

  const [pageData, setPageData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/hero-section?&locale=${lang}&populate=deep`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data) {
      setPageData(response.data.data.attributes);
    }
  }, [response]);

  return (
    <div
      className={`section-hero flex flex-col-reverse lg:flex-col relative w-full justify-center ${className}`}
    >
      <div className="flex flex-col-reverse justify-between lg:flex-row items-center gap-9 lg:gap-0">
        {loading ? (
          <div className="flex-shrink-1 flex flex-col">
            <div className="flex gap-5 self-center lg:self-start">
              <div className="w-[122px] md:w-[141px] lg:w-[161px]">
                <div
                  className="w-full bg-cover h-0 pb-[40px] md:pb-[46px] lg:pb-[53px] p-0"
                  style={{ backgroundImage: `url(${heroLogo})` }}
                />
              </div>
              <p className="font-normal text-[48px] md:text-[56px] lg:text-[64px] !leading-[1]">
                for
              </p>
            </div>
            <p className="font-normal text-[100px] md:text-[120px] lg:text-[144px] xl:text-[164px] !leading-[1]">
              TRUST
            </p>
          </div>
        ) : !pageData || error ? (
          <div className="flex w-full justify-center">
            <p>{t("fetch-error")}</p>
          </div>
        ) : (
          <>
            <div className="flex-shrink-1 flex flex-col">
              <div className="flex gap-5 self-center lg:self-start">
                <div className="w-[122px] md:w-[141px] lg:w-[161px]">
                  <div
                    className="w-full bg-cover h-0 pb-[40px] md:pb-[46px] lg:pb-[53px] p-0"
                    style={{ backgroundImage: `url(${heroLogo})` }}
                  />
                </div>
                <p className="font-normal text-[48px] md:text-[56px] lg:text-[64px] !leading-[1]">
                  {pageData.topTitle}
                </p>
              </div>
              <p className="font-normal text-[100px] md:text-[120px] lg:text-[144px] xl:text-[164px] !leading-[1]">
                {pageData.title}
              </p>
            </div>
            <div className="relative hero-right sm:w-[60%] lg:max-w-[500px] lg:max-h-[500px] xl:max-w-[500px] xl:max-h-[560px] 2xl:max-w-[660px] 2xl:max-h-[660px] rounded-full overflow-hidden signature-left">
              <img
                className="w-full"
                src={CDN_BASE_URL + pageData.image.data.attributes.url}
                alt={pageData.image.data.attributes.caption}
                loading="lazy"
              />
            </div>
          </>
        )}
      </div>
      {/* <div className="flex">
        <span className="text-lg md:text-2xl lg:pl-72">
          Bu eser, değerli sanatçı Mehmet Kösemen tarafından UNITE Edelman’ın
          25. yılına özel tasarlanmıştır. Kösemen, organik fırça darbeleri ile
          yenilikçi iletişim yönetiminin multidisipliner ve holistik doğasına
          vurgu yaparken ÜNİTE Edelman’ın sahiplendiği “güven" kavramına atıfta
          bulunmuştur.
        </span>
      </div> */}
    </div>
  );
};

export default SectionHero;
