import React from "react";
import ReactDOM from "react-dom/client";
//
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Lang
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import global_en from "./translations/en/global.json";
import global_tr from "./translations/tr/global.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "tr",
  resources: {
    tr: {
      global: global_tr,
    },
    en: {
      global: global_en,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
