import React, { Fragment, useEffect, useState, useRef, FC } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Logo from "shared/Logo/Logo";
import SocialsIcon from "shared/SocialsIcon/SocialsIcon";
import NcImage from "shared/NcImage/NcImage";
import phoneIcon from "images/phone.png";
import mailIcon from "images/mail.png";
import mapIcon from "images/map.png";
import imgRight from "images/footer-right.svg";
import imgLeft from "images/footer-left.svg";
import { useTranslation } from "react-i18next";
import Loading from "shared/Loading/Loading";
import useRequest from "hooks/useRequest";
import Gdpr from "shared/Gdpr/Gdpr";
import { Helmet } from "react-helmet";

export interface WidgetFooterMenu {
  lang: string;
}

const Footer: FC<WidgetFooterMenu> = ({ lang }) => {
  const [t] = useTranslation("global");
  let [isGDPROpen, setIsGDPROpen] = useState(false);
  let [cookieOpen, setCookieOpen] = useState(false);

  const [pageData, setPageData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/global?&locale=${lang}&populate=deep`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data) {
      setPageData(response.data.data.attributes);
    }
  }, [response]);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookieAccepted");

    if (!isCookieAccepted) {
      setCookieOpen(true);
    }
  }, []);

  const closeModal = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);
    localStorage.setItem("cookieAccepted", "true");

    setCookieOpen(false);
  };

  function closeGDPRModal() {
    setIsGDPROpen(false);
  }

  function openGDPRModal() {
    setIsGDPROpen(true);
  }

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;

    if (currentScrollPos + windowHeight >= 1000) {
      containerRef.current.classList.remove("gdpr-control");
    } else {
      containerRef.current.classList.add("gdpr-control");
    }
  };

  if (loading) {
    return (
      <div className="container relative py-16">
        <Loading />
      </div>
    );
  }

  if (!pageData || error) {
    return;
  }

  const {
    companyName,
    phone,
    phoneLink,
    mail,
    mailLink,
    address,
    addressLink,
    socials,
    seo,
  } = pageData;

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <div className="footer relative pt-16 pb-10 bg-gri overflow-hidden">
        <img
          src={imgRight}
          className="absolute -right-52 -bottom-32 md:-right-40 md:-bottom-10"
          alt="UNITE"
        />
        <img
          src={imgLeft}
          className="hidden lg:block absolute left-0 top-1/2 -translate-y-1/2"
          alt="UNITE"
        />
        <div className="container relative space-y-7 lg:space-y-10">
          <div className="flex flex-col sm:flex-row gap-5 justify-between lg:pb-4">
            <Logo />
            <div className="col-span-2 flex items-center md:col-span-3">
              <SocialsIcon
                className="flex items-center"
                rounded
                socials={socials}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-7 lg:gap-0 justify-between border-y py-9">
            <div className="contact">
              <h6 className="text-lg font-semibold pb-2">{companyName}</h6>
              <ul className="text-lg font-medium space-y-3">
                <li>
                  <a
                    href={phoneLink}
                    className="hover:text-kirmizi transition flex gap-3 items-center"
                  >
                    <NcImage
                      src={phoneIcon}
                      className="w-5"
                      alt={t("phone")}
                      containerClassName="flex-shrink-0"
                    />
                    {phone}
                  </a>
                </li>
                <li className="flex gap-3 items-center">
                  <a
                    href={mailLink}
                    className="hover:text-kirmizi transition flex gap-3 items-center"
                  >
                    <NcImage
                      src={mailIcon}
                      className="w-5"
                      alt={t("mail")}
                      containerClassName="flex-shrink-0"
                    />
                    {mail}
                  </a>
                </li>
                <li className="flex gap-3 items-center">
                  <a
                    href={addressLink}
                    target="_blank"
                    className="hover:text-kirmizi transition flex gap-3 items-center"
                  >
                    <NcImage
                      src={mapIcon}
                      className="w-5"
                      alt={t("address")}
                      containerClassName="flex-shrink-0"
                    />
                    {address}
                  </a>
                </li>
                <li className="flex gap-3 items-center">
                  <a
                    href={addressLink}
                    target="_blank"
                    className="hover:text-kirmizi border-b border-kirmizi transition flex gap-3 items-center"
                  >
                    {t("footer-map")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="links">
              <ul className="text-lg font-semibold">
                <li
                  onClick={openGDPRModal}
                  className="cursor-pointer hover:text-kirmizi transition flex gap-3 items-center"
                >
                  {t("footer-kvkk")}
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 0.999999C14 0.447715 13.5523 -5.83668e-07 13 -5.83668e-07L4 -5.83668e-07C3.44772 -5.83668e-07 3 0.447715 3 0.999999C3 1.55228 3.44772 2 4 2H12V10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10L14 0.999999ZM1.70711 13.7071L13.7071 1.70711L12.2929 0.292893L0.292893 12.2929L1.70711 13.7071Z"
                      fill="#A8272D"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>
          <div className="lg:text-right">
            <p className="text-base font-medium">
              © Copyright 2024, {t("unite")} | Created by Lunar &{" "}
              <a
                href="https://btkare.com/"
                target="_blank"
                className="hover:text-kirmizi transition"
              >
                Btkare
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Privacy Policy */}
      <Transition appear show={isGDPROpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 privacy-policy"
          onClose={closeGDPRModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <Gdpr onClick={closeGDPRModal} lang="tr" />
          </div>
        </Dialog>
      </Transition>

      {/* Cookie */}
      <div className="relative z-50 overflow-hidden">
        <div
          className={`fixed left-0 bottom-0 transition-all duration-300 gdpr-control ${
            !cookieOpen ? "-translate-x-full" : ""
          }`}
          ref={containerRef}
        >
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <div className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-2xl transition-all">
              <div className="mt-2">
                <p className="text-md text-gray-500">{t("cookie-text")}</p>
              </div>

              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 hover:bg-gray-200 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-0 transition"
                  onClick={closeModal}
                >
                  {t("accept")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
