import React, { FC, Fragment, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

// <--- NavItemType --->
export interface MegamenuItem {
  id: string;
  image: string;
  title: string;
  items: NavItemType[];
}
export interface NavItemType {
  id: string;
  name: string;
  isNew?: boolean;
  href: string;
  targetBlank?: boolean;
  children?: NavItemType[];
  megaMenu?: MegamenuItem[];
  type?: "dropdown" | "megaMenu" | "none";
}

export interface NavigationItemProps {
  menuItem: NavItemType;
  activeUrl: any;
}

type NavigationItemWithRouterProps = NavigationItemProps;

const NavigationItem: FC<NavigationItemWithRouterProps> = ({
  menuItem,
  activeUrl,
}) => {
  const [menuCurrentHovers, setMenuCurrentHovers] = useState<string[]>([]);

  // CLOSE ALL MENU OPENING WHEN CHANGE HISTORY
  const locationPathName = useLocation().pathname;
  useEffect(() => {
    setMenuCurrentHovers([]);
  }, [locationPathName]);

  const onMouseEnterMenu = (id: string) => {
    setMenuCurrentHovers((state) => [...state, id]);
  };

  const onMouseLeaveMenu = (id: string) => {
    setMenuCurrentHovers((state) => {
      return state.filter((item, index) => {
        return item !== id && index < state.indexOf(id);
      });
    });
  };

  // ===================== MENU MAIN MENU =====================
  const renderMainItem = (item: NavItemType) => {
    return (
      <NavLink
        end
        target={item.targetBlank ? "_blank" : undefined}
        rel="noopener noreferrer"
        className={`inline-flex items-center text-sm xl:text-base font-normal text-black hover:text-kirmizi transition ${
          item.href === activeUrl && "text-kirmizi"
        }`}
        to={item.href || "/"}
      >
        <span dangerouslySetInnerHTML={{ __html: item.name }} />
        <div className="h-8 w-px bg-[#292929] opacity-[10%] mx-4" />
      </NavLink>
    );
  };

  switch (menuItem.type) {
    default:
      return <li className="menu-item">{renderMainItem(menuItem)}</li>;
  }
};

export default NavigationItem;
