import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const NAVIGATION: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/#manifestomuz",
    name: "Manifestomuz",
    type: "none",
  },
  {
    id: ncNanoId(),
    href: "/#faaliyet-alanlarimiz",
    name: "Faaliyet<br/> Alanlarımız",
    type: "none",
  },
  {
    id: ncNanoId(),
    href: "/#uzmanliklarimiz",
    name: "Multidisipliner<br/> Uzmanlıklarımız",
    type: "none",
  },
  {
    id: ncNanoId(),
    href: "/#musteri-portfoyumuz",
    name: "Müşteri<br/> Portföyümüz",
    type: "none",
  },
  {
    id: ncNanoId(),
    href: "/#basari-hikayelerimiz",
    name: "Başarı<br/> Hikayelerimiz",
    type: "none",
  },
  {
    id: ncNanoId(),
    href: "/#edelman",
    name: "Edelman<br/> Trust Barometer",
    type: "none",
  },
  {
    id: ncNanoId(),
    href: "/#insan-kultur",
    name: "İnsan ve<br/> Kültür",
    type: "none",
  },
];
