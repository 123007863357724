import Heading from "components/Heading/Heading";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import useWindowSize from "hooks/useWindowResize";
import useRequest from "hooks/useRequest";
import Loading from "shared/Loading/Loading";
import { CDN_BASE_URL } from "utils/constants";

export interface SectionAwardsProps {
  className?: string;
  gridClassName?: string;
  itemPerRow?: 4 | 5;
  lang: string;
}

const SectionAwards: React.FC<SectionAwardsProps> = ({
  className = "",
  itemPerRow = 5,
  lang,
}) => {
  const [pageData, setPageData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/award?&locale=${lang}&populate=deep`,
    method: "get",
  });

  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }
  useEffect(() => {
    if (response && response.data) {
      setPageData(response.data.data.attributes);
    }
  }, [response]);

  if (loading) {
    return (
      <div className="container relative py-16">
        <Loading />
      </div>
    );
  }

  if (!pageData || error) {
    return;
  }

  const { title, logos } = pageData;

  return (
    <div
      className={`section-costumer-portfolio relative flow-root ${className}`}
    >
      <Heading
        headSignature
        headClassName="!mb-0"
        desc=""
        className="lg:mb-6 container"
      >
        {title}
      </Heading>
      {WIN_WIDTH > 768 ? (
        <div className="md:container flex lg:grid grid-cols-5 gap-0 lg:gap-6 justify-between overflow-x-auto no-scrollbar">
          {logos.map((item: any, i: number) => (
            <div
              className="w-fit flex-shrink-0 first:pl-5 last:pr-5 md:first:pl-0 md:last:pr-0"
              key={i}
            >
              <img
                src={CDN_BASE_URL + item.logo.data.attributes.url}
                alt={item.logo.data.attributes.caption}
                className="w-[220.8px]"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      ) : (
        <Splide
          options={{
            type: "loop",
            drag: "free",
            focus: "center",
            arrows: false,
            pagination: false,
            perPage: 2.5,
            autoScroll: {
              pauseOnHover: false,
              pauseOnFocus: false,
              speed: 1,
            },
          }}
          extensions={{ AutoScroll }}
        >
          {logos.map((item: any, i: number) => (
            <SplideSlide key={i}>
              <img
                src={CDN_BASE_URL + item.logo.data.attributes.url}
                alt={item.logo.data.attributes.caption}
                className="w-[220.8px]"
                loading="lazy"
              />
            </SplideSlide>
          ))}
        </Splide>
      )}
    </div>
  );
};

export default SectionAwards;
