import React from "react";
import twFocusClass from "utils/twFocusClass";

export interface ButtonCloseProps {
  className?: string;
  isWhite?: boolean;
  onClick?: () => void;
}

const ButtonClose: React.FC<ButtonCloseProps> = ({
  className = "",
  isWhite = false,
  onClick = () => {},
}) => {
  return (
    <button
      className={
        `flex w-full h-full items-center justify-center ${className} " ` +
        twFocusClass()
      }
      onClick={onClick}
    >
      <span className="sr-only">Kapat</span>
      <div className="relative w-[45px] h-[45px]">
        <div
          className={`w-[45px] h-[2px] rotate-45 top-[calc(50%_-_1px)] absolute ${
            isWhite ? "bg-white" : "bg-black"
          }`}
        />
        <div
          className={`w-[45px] h-[2px] -rotate-45 top-[calc(50%_-_1px)] absolute ${
            isWhite ? "bg-white" : "bg-black"
          }`}
        />
      </div>
    </button>
  );
};

export default ButtonClose;
