import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import NcImage from "shared/NcImage/NcImage";
import useRequest from "hooks/useRequest";
import Loading from "shared/Loading/Loading";
import { CDN_BASE_URL } from "utils/constants";

export interface SectionActivityAreasProps {
  className?: string;
  lang: string;
}

const SectionActivityAreas: FC<SectionActivityAreasProps> = ({
  className = "",
  lang,
}) => {
  const [pageData, setPageData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/scope-of-work?&locale=${lang}&populate=deep`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data) {
      setPageData(response.data.data.attributes);
    }
  }, [response]);

  if (loading) {
    return (
      <div className="container relative py-16">
        <Loading />
      </div>
    );
  }

  if (!pageData || error) {
    return;
  }

  const { title, item } = pageData;

  return (
    <div className={`section-activity-areas ${className}`}>
      <div className="flex flex-col gap-16">
        <h2 className="text-[30px] lg:text-6xl !leading-tight font-medium text-center w-full max-w-2xl mx-auto">
          {title}
        </h2>
      </div>

      <div className="mt-12 lg:mt-16 relative grid grid-cols-2 lg:grid-cols-4 gap-y-10 lg:gap-x-10 xl:gap-x-20">
        {item.map((item: any) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto cursor-pointer"
          >
            <motion.div whileHover={{ scale: 1.2, rotate: 90 }}>
              <NcImage
                containerClassName="max-w-[96px] transition mx-auto"
                src={CDN_BASE_URL + item.image.data.attributes.url}
                alt={item.image.data.attributes.url}
                loading="lazy"
              />
            </motion.div>
            <div className="text-center mt-4 lg:mt-8">
              <h3
                className="text-2xl	lg:text-4xl"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionActivityAreas;
