import React, { Fragment, useEffect, useState, FC } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import useRequest from "hooks/useRequest";

export interface GdprProps {
  lang: string;
  onClick: () => void;
}

const Gdpr: FC<GdprProps> = ({ lang, onClick }) => {
  let [isGDPROpen, setIsGDPROpen] = useState(false);

  const [pageData, setPageData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/privacy-policy?&locale=${lang}&populate=deep`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data) {
      setPageData(response.data.data.attributes);
    }
  }, [response]);

  if (!pageData || error) {
    return;
  }

  const { title, description } = pageData;

  function closeGDPRModal() {
    setIsGDPROpen(false);
  }

  return (
    <div className="flex min-h-full items-center justify-center p-4 text-center">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
          <div className="flex justify-end mb-4">
            <ButtonClose className="!justify-end w-fit" onClick={onClick} />
          </div>
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 mb-5 text-black"
          >
            {title}
          </Dialog.Title>
          <div
            className="mt-2 text-sm text-gray-800"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Dialog.Panel>
      </Transition.Child>
    </div>
  );
};

export default Gdpr;
