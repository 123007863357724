import React, { HTMLAttributes, ReactNode } from "react";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  isCenter?: boolean;
  isWhite?: boolean;
  headSignature?: boolean;
  headClassName?: string;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  desc = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  className = "mb-14",
  headClassName = "",
  isCenter = false,
  isWhite = false,
  headSignature = false,
  ...args
}) => {
  return (
    <div
      className={`section-heading relative flex flex-row gap-14 items-center ${
        isWhite ? "text-white" : "text-black"
      } ${headSignature ? "head-signature" : ""} ${className}`}
    >
      <div
        className={`flex flex-col ${
          isCenter ? "text-center w-full mx-auto max-w-5xl" : "max-w-6xl"
        }`}
      >
        <h2
          className={`text-[30px] lg:text-6xl leading-[1] font-medium mb-6 text-wrap head-text flex items-center gap-5 ${
            isCenter ? "self-center" : ""
          } ${headClassName}`}
          {...args}
        >
          {children || `Section Heading`}
        </h2>
        {desc && (
          <span className={`font-normal block text-lg lg:text-2xl`}>
            {desc}
          </span>
        )}
      </div>
    </div>
  );
};

export default Heading;
