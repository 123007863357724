import React, { FC } from "react";

export interface SocialsIconProps {
  className?: string;
  itemClass?: string;
  rounded?: boolean;
  light?: boolean;
  socials: any[];
}

const SocialsIcon: FC<SocialsIconProps> = ({
  className = "",
  itemClass = "block",
  rounded = false,
  light = false,
  socials,
}) => {
  return (
    <nav className={`flex space-x-5 ${className}`}>
      {socials.map((item, i) => (
        <a
          key={i}
          className={`cursor-pointer ${itemClass} 
          ${
            rounded
              ? "flex border border-black flex-shrink-0 p-[5px] rounded-full hover:scale-110 transition-all duration-200"
              : ""
          }`}
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <img
            src={
              light
                ? require("../../images/icons/" + item.icon + "_light.png")
                : require("../../images/icons/" + item.icon + ".png")
            }
            alt={`ÜNİTE ` + item.name}
            className="w-[34px] lg:w-[52px] flex-shrink-0"
          />
        </a>
      ))}
    </nav>
  );
};

export default SocialsIcon;
