import React, { FC, useEffect, useState } from "react";
import { CDN_BASE_URL } from "utils/constants";
import useRequest from "hooks/useRequest";
import Loading from "shared/Loading/Loading";

export interface SectionVideoProps {
  className?: string;
  lang: string;
}

const SectionVideo: FC<SectionVideoProps> = ({ className = "", lang }) => {
  const [pageData, setPageData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/video?&locale=${lang}&populate=deep`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data) {
      setPageData(response.data.data.attributes);
    }
  }, [response]);

  if (loading) {
    return (
      <div className="container relative py-16">
        <Loading />
      </div>
    );
  }

  if (!pageData || error) {
    return;
  }

  const { title, media, buttons } = pageData;

  return (
    <div className="relative py-24 lg:py-36">
      <div
        className={`background-section absolute flex inset-y-0 w-screen left-1/2 transform -translate-x-1/2 z-0 bg-neutral-100 overflow-hidden`}
      >
        <div className="video-bg self-center before:absolute before:w-full before:h-full before:bg-[#21386A] before:mix-blend-multiply before:top-0">
          <video
            autoPlay
            muted
            loop
            playsInline
            src={CDN_BASE_URL + media.data.attributes.url}
            className=" object-cover h-screen lg:object-[unset] lg:h-auto"
          />
        </div>
      </div>
      <div
        className={`section-hero relative flex flex-col justify-between lg:items-center self-center text-white gap-10 lg:gap-16 ${className}`}
      >
        <h4 className="font-normal text-left text-6xl lg:text-8xl !leading-[1] font-['Bebas_Neue']">
          {title}
        </h4>
        <div className="flex flex-col lg:flex-row gap gap-4 lg:gap-24">
          {buttons.map((item: any, i: number) => (
            <a
              href={item.link}
              target="_blank"
              className="text-4xl cursor-pointer flex gap-4 items-center hover:scale-110 transition"
              key={i}
            >
              {item.text}
              <svg
                width="23"
                height="24"
                viewBox="0 0 23 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23 1.5C23 0.947717 22.5523 0.500002 22 0.500001L13 0.500001C12.4477 0.500002 12 0.947717 12 1.5C12 2.05229 12.4477 2.5 13 2.5L21 2.5L21 10.5C21 11.0523 21.4477 11.5 22 11.5C22.5523 11.5 23 11.0523 23 10.5L23 1.5ZM1.70711 23.2071L22.7071 2.20711L21.2929 0.792895L0.292893 21.7929L1.70711 23.2071Z"
                  fill="white"
                />
              </svg>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionVideo;
