import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import useWindowSize from "hooks/useWindowResize";
import ScrollToAnchor from "./ScrollToAnchor";
import SiteHeader from "containers/SiteHeader";
import { useTranslation } from "react-i18next";

export const pages: Page[] = [
  { path: "/", exact: true },
  { path: "/#", exact: true },
  { path: "/tr", exact: true },
  { path: "/en", exact: true },
];

const MyRoutes = () => {
  const [t, i18n] = useTranslation("global");
  const langFromUrl = window.location.pathname.startsWith("/en")
    ? "en"
    : window.location.pathname.startsWith("/tr")
    ? "tr"
    : null;

  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  useEffect(() => {
    let storedLang = localStorage.getItem("language");

    if (!storedLang) {
      if (langFromUrl === null) {
        storedLang = i18n.options.lng || "tr";
        i18n.changeLanguage(storedLang);
        localStorage.setItem("language", storedLang);
      } else {
        i18n.changeLanguage(langFromUrl);
        localStorage.setItem("language", langFromUrl);
      }
    } else {
      if (langFromUrl === null) {
        i18n.changeLanguage(storedLang);
      } else {
        i18n.changeLanguage(langFromUrl);
        localStorage.setItem("language", langFromUrl);
      }
    }
  }, []);

  document.documentElement.lang = i18n.language;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ScrollToAnchor />
      <SiteHeader lang={document.documentElement.lang} />

      <Routes>
        {pages.map(({ path }) => {
          return (
            <Route
              key={path}
              element={<PageHome lang={document.documentElement.lang} />}
              path={path}
            />
          );
        })}
        <Route path="*" element={<Page404 />} />
      </Routes>

      <Footer lang={document.documentElement.lang} />
    </BrowserRouter>
  );
};

export default MyRoutes;
