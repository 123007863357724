import React, { FC, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import useWindowSize from "hooks/useWindowResize";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { useTranslation } from "react-i18next";

export interface CreativeAreaProps {
  className?: string;
  gridClassName?: string;
  data: any;
}

const CreativeArea: FC<CreativeAreaProps> = ({
  className = "",
  gridClassName = "grid-cols-2",
  data,
}) => {
  const [t] = useTranslation("global");
  const { secondArea, secondAreaTitle, secondAreaText } = data;

  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }
  let gridStyle: any = {};

  if (WIN_WIDTH > 768) {
    gridStyle = {
      display: "grid",
      "--grid-col": Math.ceil(secondArea.length / 2),
      gridAutoFlow: "column",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "repeat(var(--grid-col), 1fr)",
    };
  } else {
    gridStyle = {
      display: "grid",
    };
  }

  return (
    <Popover className="block">
      {({ close }) => (
        <>
          <Popover.Button
            className={`text-xl md:text-2xl font-medium p-6 pb-0 w-full lg:hover:scale-125 transition flex flex-col gap-5 items-center`}
          >
            <span dangerouslySetInnerHTML={{ __html: secondAreaTitle }} />
            <div className="block exit-btn">
              <svg
                width="20"
                height="23"
                viewBox="0 0 20 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.8405 1.44043C19.8405 0.85235 19.4238 0.375619 18.9098 0.375618L10.5336 0.375619C10.0196 0.375619 9.60292 0.852351 9.60292 1.44043C9.60292 2.02851 10.0196 2.50524 10.5336 2.50524L17.9791 2.50524L17.9791 11.0237C17.9791 11.6118 18.3958 12.0885 18.9098 12.0885C19.4238 12.0885 19.8405 11.6118 19.8405 11.0237L19.8405 1.44043ZM1.43153 22.9434L19.5679 2.19336L18.2517 0.687495L0.115342 21.4375L1.43153 22.9434Z"
                  fill="black"
                />
              </svg>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 top-0 -translate-y-1/2 z-10 w-screen -translate-x-1/2 transform px-0 h-full">
              <div className="overflow-hidden h-full bg-white border-t lg:border-t-0">
                <div className="relative container grid gap-8 lg:p-7 h-full items-start lg:items-center">
                  <div className="absolute top-8 lg:top-32 right-5 lg:right-32 transition duration-150 ease-in-out cursor-pointer">
                    <ButtonClose onClick={() => close()} />
                  </div>
                  <span className="text-4xl lg:text-9xl font-bold text-black opacity-10 block absolute mt-[38px] lg:mt-0 lg:-rotate-90 left-4 lg:-left-60 xl:-left-52 2xl:-left-24">
                    {secondAreaText}
                  </span>
                  <div
                    className="lg:gap-x-24 gap-y-3 lg:gap-y-4 mt-24 lg:mt-0 w-full lg:w-[800px] mx-auto"
                    style={gridStyle}
                  >
                    {secondArea.map((item: any, index: number) => (
                      <div
                        className="flex gap-6 lg:gap-9 items-center min-w-[346px] max-w-[346px] text-black text-lg lg:text-2xl"
                        key={index}
                      >
                        <span className="opacity-30">
                          {(index + 1).toString().padStart(2, "0")}
                        </span>
                        <span className="text-left pr-12 md:pr-0">
                          {item.text}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default CreativeArea;
