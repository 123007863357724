import React, { FC } from "react";
import Header from "components/Header/Header";

interface HeaderProps {
  lang: string;
}

const SiteHeader: FC<HeaderProps> = ({ lang }) => {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <Header lang={lang} />
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  );
};

export default SiteHeader;
