import React from "react";

const LogoSvg = () => {
  return (
    <svg
      width="156"
      height="80"
      viewBox="0 0 156 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-[130px] h-[66px] md:w-[156px] md:h-[80px]"
    >
      <path d="M68.9817 67.3762L80.7063 80V67.3762H68.9817Z" fill="#06326E" />
      <path
        d="M80.7063 54.7524V67.3732H92.431L80.7063 54.7524Z"
        fill="#007BAA"
      />
      <path d="M80.7063 67.3762V80L92.431 67.3762H80.7063Z" fill="#004F85" />
      <path
        d="M94.8955 70.6735V64.3737H99.2015V65.5951H96.3597V66.7444H99.0115V67.9659H96.3597V69.4521H99.4474V70.6735H94.8955Z"
        fill="#007BAA"
      />
      <path
        d="M102.597 70.6735V70.2132H102.58C102.367 70.5051 101.979 70.7969 101.403 70.7969C100.353 70.7969 99.5144 70.0628 99.4893 68.354C99.4641 66.4766 100.467 65.8388 101.347 65.8388C101.702 65.8388 102.169 65.911 102.549 66.3352V64.3707H103.832V70.6735H102.597ZM101.652 66.8497C101.043 66.8497 100.772 67.4153 100.772 68.3179C100.772 69.088 100.942 69.786 101.652 69.786C102.317 69.786 102.549 69.1211 102.549 68.2186C102.549 67.3792 102.317 66.8467 101.652 66.8467"
        fill="#007BAA"
      />
      <path
        d="M105.522 68.6037C105.53 69.5062 106.064 69.789 106.478 69.789C106.953 69.789 107.176 69.5243 107.545 68.9587L108.506 69.5002C108.062 70.3847 107.363 70.7999 106.386 70.7999C105.03 70.7999 104.242 69.792 104.242 68.4653C104.242 66.8016 105.055 65.8478 106.386 65.8478C107.978 65.8478 108.523 67.1566 108.531 68.6097H105.522V68.6037ZM107.249 67.7553C107.207 67.0633 106.746 66.8527 106.377 66.8527C106.008 66.8527 105.564 67.1355 105.505 67.7553H107.249Z"
        fill="#007BAA"
      />
      <path
        d="M110.281 64.3737H108.998V70.6735H110.281V64.3737Z"
        fill="#007BAA"
      />
      <path
        d="M111.004 65.9652H112.287V66.3804C112.617 65.9652 113.159 65.8388 113.553 65.8388C114.056 65.8388 114.425 66.0494 114.712 66.4676C115.132 66.0073 115.609 65.8388 116.118 65.8388C116.545 65.8388 117.465 65.9983 117.465 67.0783V70.6705H116.182V67.9177C116.182 67.2528 116.009 66.955 115.525 66.955C115.098 66.955 114.874 67.298 114.874 67.7763V70.6705H113.592V67.7222C113.592 67.2619 113.461 66.955 112.969 66.955C112.449 66.955 112.287 67.316 112.287 67.7432V70.6705H111.004V65.9622V65.9652Z"
        fill="#007BAA"
      />
      <path
        d="M121.877 69.6928C121.877 70.0207 121.885 70.3817 121.991 70.6735H120.809C120.759 70.5141 120.734 70.3456 120.726 70.1862H120.709C120.494 70.5562 119.854 70.7969 119.393 70.7969C118.554 70.7969 117.931 70.3035 117.931 69.3979C117.931 68.2667 118.778 67.8666 120.175 67.656L120.645 67.5838V67.1927C120.645 66.8407 120.382 66.6872 120.035 66.6872C119.625 66.6872 119.426 66.8467 119.362 67.316H118.13C118.172 65.9622 119.34 65.8388 119.929 65.8388C121.122 65.8388 121.868 66.1397 121.877 67.2017V69.6928ZM120.645 68.381L119.781 68.6458C119.46 68.7421 119.166 68.8774 119.166 69.2565C119.166 69.6356 119.454 69.786 119.767 69.786C120.267 69.786 120.647 69.425 120.647 68.706V68.378L120.645 68.381Z"
        fill="#007BAA"
      />
      <path
        d="M122.634 65.9652H123.917V66.3804C124.246 65.9652 124.788 65.8388 125.182 65.8388C126.216 65.8388 126.507 66.4857 126.507 67.1927V70.6705H125.224V67.7222C125.224 67.2619 125.093 66.955 124.601 66.955C124.082 66.955 123.919 67.316 123.919 67.7432V70.6705H122.637V65.9622L122.634 65.9652Z"
        fill="#007BAA"
      />
      <path
        d="M132.646 63.5553H133.073L134.306 70.6766H133.898L133.518 68.3931H130.324L129.044 70.6766H128.58L132.646 63.5553ZM130.533 68.014H133.451L132.783 63.9946L130.533 68.014Z"
        fill="#007BAA"
      />
      <path
        d="M136.083 65.55L136.167 65.1499C136.315 64.4218 136.454 63.4741 137.474 63.4741C137.687 63.4741 137.891 63.5042 137.975 63.5253L137.91 63.8532C137.798 63.8321 137.662 63.802 137.541 63.802C136.753 63.802 136.653 64.5391 136.521 65.1469L136.438 65.547H137.446L137.379 65.8779H136.371L135.415 70.6735H135.063L136.007 65.8779H135.191L135.256 65.547H136.08L136.083 65.55Z"
        fill="#007BAA"
      />
      <path
        d="M138.315 65.55L138.399 65.1499C138.547 64.4218 138.687 63.4741 139.707 63.4741C139.919 63.4741 140.123 63.5042 140.207 63.5253L140.14 63.8532C140.028 63.8321 139.891 63.802 139.771 63.802C138.983 63.802 138.883 64.5391 138.751 65.1469L138.667 65.547H139.676L139.609 65.8779H138.6L137.645 70.6735H137.293L138.237 65.8779H137.421L137.488 65.547H138.313L138.315 65.55Z"
        fill="#007BAA"
      />
      <path
        d="M140.665 65.55L139.673 70.6766H139.321L140.311 65.55H140.665ZM141.054 63.5553L140.858 64.5843H140.506L140.699 63.5553H141.054Z"
        fill="#007BAA"
      />
      <path
        d="M142.25 63.5553H142.602L141.221 70.6735H140.869L142.25 63.5553Z"
        fill="#007BAA"
      />
      <path
        d="M143.407 65.55H143.759L142.767 70.6766H142.415L143.407 65.55ZM144.147 63.5553L143.951 64.5843H143.599L143.792 63.5553H144.147Z"
        fill="#007BAA"
      />
      <path
        d="M144.563 67.0242C144.815 65.887 145.544 65.4297 146.609 65.4297C147.089 65.4297 148.081 65.6192 148.081 66.6451C148.081 67.0934 147.802 68.2396 147.766 68.3991C147.654 68.9376 147.453 69.7439 147.453 70.0357C147.453 70.2162 147.536 70.3637 147.712 70.3637C147.813 70.3637 147.928 70.3336 148.028 70.3035L147.972 70.6435C147.833 70.6826 147.685 70.6946 147.564 70.6946C147.24 70.6946 147.101 70.3937 147.101 70.1651C147.101 70.0658 147.156 69.8673 147.176 69.7349H147.156C146.776 70.475 145.988 70.7909 145.36 70.7909C144.547 70.7909 143.887 70.4028 143.887 69.416C143.887 68.1103 144.896 67.8485 145.98 67.8305C147.305 67.7793 147.729 67.5808 147.729 66.8046C147.729 66.1668 147.397 65.7576 146.536 65.7576C145.675 65.7576 145.139 66.1547 144.924 67.0242H144.563ZM147.545 67.7823H147.525C147.265 68.0621 146.452 68.1223 146.044 68.1614C145.156 68.1825 144.239 68.2908 144.239 69.419C144.239 70.1862 144.823 70.466 145.452 70.466C146.589 70.466 147.117 69.5483 147.38 68.4803L147.545 67.7823Z"
        fill="#007BAA"
      />
      <path
        d="M148.908 65.55H149.789L150.113 63.9434H150.465L150.141 65.55H151.161L151.088 65.8809H150.088L149.367 69.4491C149.347 69.5183 149.291 69.8281 149.291 69.9184C149.291 70.2072 149.448 70.3456 149.828 70.3456C150.004 70.3456 150.18 70.3366 150.356 70.3155L150.292 70.6465C150.171 70.6465 149.939 70.6766 149.719 70.6766C149.163 70.6766 148.939 70.3968 148.939 69.9094C148.939 69.78 148.987 69.5724 149.015 69.422L149.738 65.884H148.839L148.914 65.553L148.908 65.55Z"
        fill="#007BAA"
      />
      <path
        d="M151.585 68.1614C151.557 68.3299 151.541 68.5285 151.541 68.721C151.541 69.6567 152.085 70.466 153.049 70.466C153.938 70.466 154.679 69.7589 154.846 68.9707H155.198C154.922 70.0387 154.153 70.7939 153.013 70.7939C151.809 70.7939 151.189 69.8673 151.189 68.7691C151.189 66.8647 152.153 65.4297 153.717 65.4297C154.838 65.4297 155.458 66.2781 155.458 67.4033C155.458 67.662 155.43 67.9117 155.394 68.1614H151.585ZM155.059 67.8305C155.087 67.662 155.106 67.5296 155.106 67.4003C155.106 66.5248 154.653 65.7546 153.653 65.7546C152.533 65.7546 151.856 66.7414 151.644 67.8275H155.061L155.059 67.8305Z"
        fill="#007BAA"
      />
      <path
        d="M5.2001 30.9458C5.2001 35.1818 5.2001 39.7969 8.92484 43.2026C10.8221 44.9415 14.0551 46.0788 16.6537 46.0788C19.2524 46.0788 22.4853 44.9445 24.3826 43.2026C28.1074 39.7969 28.1074 35.1818 28.1074 30.9458V0H33.3075V32.4591C33.3075 36.6951 32.8855 40.7807 30.3567 44.5655C27.5457 48.7263 22.6278 51.3738 16.6537 51.3738C10.6796 51.3738 5.76175 48.7263 2.95073 44.5655C0.421932 40.7807 0 36.6951 0 32.4591V0H5.2001V30.9458Z"
        fill="black"
      />
      <path
        d="M87.0605 0.550562V50.8232C87.0605 51.1271 86.8313 51.3738 86.5491 51.3738H82.2767C81.9945 51.3738 81.7654 51.1271 81.7654 50.8232V0.550562C81.7654 0.2467 81.9945 0 82.2767 0H86.5491C86.8313 0 87.0605 0.2467 87.0605 0.550562Z"
        fill="black"
      />
      <path
        d="M111.53 5.94186V50.8232C111.53 51.1271 111.301 51.3738 111.018 51.3738H106.746C106.464 51.3738 106.235 51.1271 106.235 50.8232V5.94186C106.235 5.638 106.005 5.3913 105.723 5.3913H95.1582C94.876 5.3913 94.6469 5.1446 94.6469 4.84074V0.550562C94.6469 0.2467 94.876 0 95.1582 0H122.606C122.888 0 123.117 0.2467 123.117 0.550562V4.84074C123.117 5.1446 122.888 5.3913 122.606 5.3913H112.038C111.756 5.3913 111.527 5.638 111.527 5.94186"
        fill="black"
      />
      <path
        d="M154.944 5.3913H136.508C136.225 5.3913 135.996 5.638 135.996 5.94186V20.091C135.996 20.3949 136.225 20.6416 136.508 20.6416H154.371C154.653 20.6416 154.883 20.8883 154.883 21.1921V25.4823C154.883 25.7862 154.653 26.0329 154.371 26.0329H136.508C136.225 26.0329 135.996 26.2796 135.996 26.5834V45.4319C135.996 45.7358 136.225 45.9825 136.508 45.9825H154.944C155.226 45.9825 155.455 46.2292 155.455 46.533V50.8232C155.455 51.1271 155.226 51.3738 154.944 51.3738H131.215C130.933 51.3738 130.704 51.1271 130.704 50.8232V0.550562C130.704 0.2467 130.933 0 131.215 0H154.944C155.226 0 155.455 0.2467 155.455 0.550562V4.84074C155.455 5.1446 155.226 5.3913 154.944 5.3913Z"
        fill="black"
      />
      <path
        d="M68.9817 20.428C68.9817 16.1919 68.9817 11.5768 65.2569 8.17119C63.3596 6.43225 60.1267 5.29503 57.528 5.29503C54.9294 5.29503 51.6964 6.42924 49.7991 8.17119C46.0744 11.5768 46.0744 16.1919 46.0744 20.428V51.3738H40.8743V35.1442V18.9147C40.8743 14.6787 41.2962 10.5931 43.825 6.80832C46.6333 2.64751 51.5539 0 57.5252 0C63.4966 0 68.4172 2.64751 71.2282 6.80832C73.757 10.5931 74.179 14.6787 74.179 18.9147V51.3738H68.9789V20.428H68.9817Z"
        fill="black"
      />
      <path
        d="M74.1818 28.6292C74.1818 25.5184 71.843 22.9942 68.9537 22.9852V34.2702C71.843 34.2612 74.1818 31.7401 74.1818 28.6262"
        fill="#A8272D"
      />
    </svg>
  );
};

export default LogoSvg;
