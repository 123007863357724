import React, { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export interface LangSwitchProps {
  className?: string;
}

const LangSwitch: FC<LangSwitchProps> = ({ className = "" }) => {
  const [t, i18n] = useTranslation("global");

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  const LANG = [
    { code: "tr", label: t("turkish") },
    { code: "en", label: t("english") },
  ];

  return (
    <ul className={`flex gap-[18px] items-center ${className}`}>
      {LANG.map((item, index) => (
        <li
          key={index}
          className={`text-xl lg:text-base font-normal text-black cursor-pointer select-none transition-colors
          ${i18n.language === item.code && "text-kirmizi font-semibold"}
        `}
          onClick={() => handleChangeLanguage(item.code)}
        >
          {item.label}
        </li>
      ))}
    </ul>
  );
};

export default LangSwitch;
