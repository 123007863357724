import React, { useState, Fragment, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import NavMobile from "shared/Navigation/NavMobile";
import { useLocation } from "react-router-dom";
import LangSwitch from "components/LangSwitch/LangSwitch";

export interface MenuBarProps {
  className?: string;
  iconClassName?: string;
  navigationData: any;
}
const MenuBar: React.FC<MenuBarProps> = ({
  className = "p-2.5",
  iconClassName = "h-7 w-7",
  navigationData,
}) => {
  const [isVisable, setIsVisable] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsVisable(false);
  }, [location]);

  const handleOpenMenu = () => setIsVisable(true);
  const handleCloseMenu = () => setIsVisable(false);

  const renderContent = () => {
    return (
      <div
        className="mobileMenuBar relative z-50 overflow-hidden"
        onClick={handleCloseMenu}
      >
        <div
          className={`fixed inset-0 bg-neutral-900 bg-opacity-50 ${
            !isVisable ? "hidden" : "visible"
          }`}
        />
        <div
          className={`fixed inset-0 transition-all duration-300 ${
            !isVisable ? "translate-x-full" : ""
          }`}
        >
          <div className="flex justify-end min-h-full ">
            <div className="w-full max-w-[75%] sm:max-w-md transform overflow-hidden transition-all ">
              <NavMobile
                navigationData={navigationData}
                onClickClose={handleCloseMenu}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <LangSwitch />
      <div
        className={`ml-4 hamburger-icon relative ${className}`}
        onClick={handleOpenMenu}
      >
        <div className="relative">
          <div className="w-[45px] h-[2px] bg-black mb-[20px]" />
          <div className="w-[45px] h-[2px] bg-black" />
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default MenuBar;
