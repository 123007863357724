const API_BASE_URL = "https://dashboard.unite.com.tr/api";
const CDN_BASE_URL = "https://dashboard.unite.com.tr";

const FALLBACK_SEO = {
  title: "UNITE Edelman Affiliate",
  description:
    "Ulusal ve uluslararası itibarlı kurum ve markaların GÜVEN ORTAĞIYIZ. İş ve pazarlama hedefleri doğrultusunda bütünleşik iletişim danışmanlığı sunuyor ve hayata geçiriyoruz.",
};

export { API_BASE_URL, CDN_BASE_URL, FALLBACK_SEO };
