import React, { useLayoutEffect, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION } from "data/navigation";
//import LangSwitch from "components/LangSwitch/LangSwitch";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
  navigationData: any;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION,
  navigationData,
  onClickClose,
}) => {
  const clamp = (value: number) => Math.max(0, value);
  const isBetween = (value: number, floor: number, ceil: number) =>
    value >= floor && value <= ceil;

  // hooks
  const useScrollspy = (ids: string[], offset: number = 0) => {
    const [activeId, setActiveId] = useState("");

    useLayoutEffect(() => {
      const listener = () => {
        const scroll = window.pageYOffset;

        const position = ids
          .map((id) => {
            const element = document.getElementById(id);

            if (!element) return { id, top: -1, bottom: -1 };

            const rect = element.getBoundingClientRect();
            const top = clamp(rect.top + scroll - offset);
            const bottom = clamp(rect.bottom + scroll - offset);

            return { id, top, bottom };
          })
          .find(({ top, bottom }) => isBetween(scroll, top, bottom));

        setActiveId(position?.id || "");
      };

      listener();

      window.addEventListener("resize", listener);
      window.addEventListener("scroll", listener);

      return () => {
        window.removeEventListener("resize", listener);
        window.removeEventListener("scroll", listener);
      };
    }, [ids, offset]);

    return activeId;
  };

  // const ids = [
  //   "manifestomuz",
  //   "faaliyet-alanlarimiz",
  //   "uzmanliklarimiz",
  //   "musteri-portfoyumuz",
  //   "basari-hikayelerimiz",
  //   "edelman",
  //   "insan-kultur",
  // ];

  const ids = navigationData.map((item: any) =>
    item.href.replace(/^\/?#?/, "")
  );
  const activeId = useScrollspy(ids, 125);

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure key={item.id} as="li">
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium text-xl text-black hover:text-kirmizi transition ${
              item.href === "/#" + activeId && "text-kirmizi"
            }`
          }
          to={item.href || "/"}
        >
          <span
            className={`py-2.5 pr-3 no-mobile-br ${
              !item.children ? "block w-full" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: item.name }}
          />
        </NavLink>
      </Disclosure>
    );
  };

  return (
    <div className="overflow-y-auto w-full h-screen transition transform ring-0 bg-white background-mobile-hero">
      <div className="p-4 bg-gri h-[98px] flex items-center justify-end">
        <span className="relative align-end p-2.5">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {navigationData.map(_renderItem)}
      </ul>
    </div>
  );
};

export default NavMobile;
