import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";
import { useTranslation } from "react-i18next";

const Page404: React.FC = () => {
  const [t] = useTranslation("global");

  return (
    <div className="page-404">
      <Helmet>
        <title>{t("not-found-title")} | UNITE Edelman Affiliate</title>
      </Helmet>
      <div className="container relative pb-16 lg:pb-20 pt-36">
        <header className="text-center max-w-2xl mx-auto space-y-2">
          <NcImage src={I404Png} alt="Sayfa Bulunamadı" />
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
            {t("not-found")}
          </span>
          <div className="pt-8">
            <ButtonPrimary href="/">{t("return-home")}</ButtonPrimary>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Page404;
