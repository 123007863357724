import React, { FC } from "react";

export interface BackgroundSectionProps {
  className?: string;
  children?: React.ReactNode;
}

const BackgroundSection: FC<BackgroundSectionProps> = ({
  className = "bg-neutral-100",
  children,
}) => {
  return (
    //xl:max-w-[1340px] 2xl:max-w-screen-2xl
    <div
      className={`background-section absolute inset-y-0 w-screen left-1/2 transform -translate-x-1/2 z-0 ${className}`}
      data-nc-id="background-section"
    >
      {children}
    </div>
  );
};

export default BackgroundSection;
