import React, { FC, useEffect, useState } from "react";
import useRequest from "hooks/useRequest";
import Loading from "shared/Loading/Loading";
import { CDN_BASE_URL } from "utils/constants";

export interface SectionHRProps {
  className?: string;
  lang: string;
}

const SectionHR: FC<SectionHRProps> = ({ className = "", lang }) => {
  const [pageData, setPageData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/human-resource?&locale=${lang}&populate=deep`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data) {
      setPageData(response.data.data.attributes);
    }
  }, [response]);

  if (loading) {
    return (
      <div className="container relative py-16">
        <Loading />
      </div>
    );
  }

  if (!pageData || error) {
    return;
  }

  const { description, image } = pageData;

  return (
    <div
      className={`section-hr relative flex flex-col-reverse justify-between lg:flex-row lg:items-center gap-11 lg:gap-16 ${className}`}
    >
      <div className="flex flex-col gap-8 lg:gap-12">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="relative w-full h-full self-start lg:self-center md:w-[400px] md:h-[400px] lg:w-[446px] lg:h-[446px] xl:w-[546px] xl:h-[546px] rounded-full overflow-hidden signature-left flex-shrink-0">
        <img
          className="w-full"
          src={CDN_BASE_URL + image.data.attributes.url}
          alt={image.data.attributes.caption}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default SectionHR;
