import React from "react";
import ButtonThird from "shared/Button/ButtonThird";

export interface LoadingProps {
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({ className = "" }) => {
  return (
    <div
      className={`loading relative flex items-center text-paragraph-base px-6 pt-4 pb-3 rounded-lg justify-center ${className}`}
    >
      <ButtonThird className="border-none" loading>
        Yükleniyor...
      </ButtonThird>
    </div>
  );
};

export default Loading;
