import React, { FC, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionManifesto from "components/SectionManifesto/SectionManifesto";
import SectionActivityAreas from "components/SectionActivityAreas/SectionActivityAreas";
import SectionExpertiseAreas from "components/SectionExpertiseAreas/SectionExpertiseAreas";
import SectionCostumerPortfolio from "components/SectionCostumerPortfolio/SectionCostumerPortfolio";
import SectionValues from "components/SectionValues/SectionValues";
import SectionAwards from "components/SectionAwards/SectionAwards";
import SectionCertificates from "components/SectionCertificates/SectionCertificates";
import SectionTypewriter from "components/SectionTypewriter/SectionTypewriter";
import SectionVideo from "components/SectionVideo/SectionVideo";
import SectionHR from "components/SectionHR/SectionHR";

interface Props {
  lang: string;
  item: any;
}

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};

const PageLayout: FC<Props> = ({ item, lang }) => {
  if (item) {
    if (item.__component === "homepage.manifesto") {
      return (
        <motion.div
          className="handle container relative pt-24"
          layout
          transition={{ type: "spring" }}
          id={item.selectorId}
        >
          <BackgroundSection className="h-[calc(100%-400px)] bg-[#354AD1] signature-manifesto" />
          <SectionManifesto lang={lang} />
        </motion.div>
      );
    } else if (item.__component === "homepage.scope-of-work") {
      return (
        <div className="container" id={item.selectorId}>
          <SectionActivityAreas className="py-[75px] lg:py-28" lang={lang} />
        </div>
      );
    } else if (item.__component === "homepage.multidisciplinary-services") {
      return (
        <div className="relative container" id={item.selectorId}>
          <BackgroundSection className="bg-expertise-bg bg-cover bg-no-repeat bg-center" />
          <SectionExpertiseAreas className="py-14 lg:py-20" lang={lang} />
        </div>
      );
    } else if (item.__component === "homepage.client-portfolio") {
      return (
        <div className="relative py-16 lg:py-24" id={item.selectorId}>
          <BackgroundSection className="bg-green" />
          <SectionCostumerPortfolio lang={lang} />
        </div>
      );
    } else if (item.__component === "homepage.values") {
      return (
        <div id={item.selectorId}>
          <SectionValues className="pt-[75px] lg:pt-24" lang={lang} />
          <SectionAwards className="pt-[75px] lg:pt-24" lang={lang} />
          <SectionCertificates className="py-[75px] lg:py-24" lang={lang} />

          <div className="relative container py-[75px] lg:py-24 min-h-[250px] flex justify-center items-center">
            <BackgroundSection className="bg-gri" />
            <SectionTypewriter lang={lang} />
          </div>
        </div>
      );
    } else if (item.__component === "homepage.video") {
      return (
        <div className="container" id={item.selectorId}>
          <SectionVideo lang={lang} />
        </div>
      );
    } else if (item.__component === "homepage.human-resources") {
      return (
        <div className="container" id={item.selectorId}>
          <SectionHR className="pt-[75px] lg:py-24" lang={lang} />
        </div>
      );
    }
  }
  return null;
};

export default PageLayout;
