import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import MenuBar from "shared/MenuBar/MenuBar";
import { Transition } from "@headlessui/react";
import ncNanoId from "utils/ncNanoId";
import { useTranslation } from "react-i18next";
import useRequest from "hooks/useRequest";

export interface MainNavProps {
  className?: string;
  lang: string;
}

const MainNav: FC<MainNavProps> = ({ className = "", lang }) => {
  const [t] = useTranslation("global");
  const [isNavOpen, setIsNavOpen] = useState(false);

  const language = lang === "tr" ? 1 : 2;

  const [menuData, setMenuData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/menus/${language}?nested&populate=*`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data) {
      setMenuData(response.data.data.attributes);
    }
  }, [response]);

  const handleToggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  if (!menuData || error) {
    return;
  }

  function transformData(items: any) {
    return items.data.map((item: any) => ({
      id: ncNanoId(),
      href: `/${item.attributes.url}`,
      name: item.attributes.title,
      type: "none",
    }));
  }

  const transformedData = transformData(menuData.items);

  return (
    <>
      <div className={`navigation relative z-10 ${className}`}>
        <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
          <div
            className="flex justify-start flex-1 items-center space-x-4 sm:space-x-10"
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <Logo />
          </div>

          <div className="flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none">
            <Transition
              appear
              show={isNavOpen}
              enter="ease-out transition-transform"
              enterFrom="opacity-0 translate-x-20"
              enterTo="opacity-100 translate-x-0"
              leave="ease-out transition-transform"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-20"
              className="transition"
            >
              <Navigation navigationData={transformedData} />
            </Transition>
            <div className="hidden lg:flex items-center space-x-0.5 transition">
              <div
                className={`hamburger-icon relative ${className}`}
                onClick={handleToggleNav}
              >
                <input id="menu-anchor" type="checkbox" />
                <label htmlFor="menu-anchor">
                  <div className="menu">
                    <div className="menu_part"></div>
                    <div className="menu_part"></div>
                  </div>
                </label>
              </div>
            </div>
            <div className="flex lg:hidden items-center">
              <MenuBar navigationData={transformedData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainNav;
