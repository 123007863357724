import useRequest from "hooks/useRequest";
import React, { useEffect, useState } from "react";
import Loading from "shared/Loading/Loading";
import Typewriter from "typewriter-effect";

export interface SectionTypewriterProps {
  className?: string;
  lang: string;
}

const SectionTypewriter: React.FC<SectionTypewriterProps> = ({
  className = "",
  lang,
}) => {
  const [pageData, setPageData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/typewriter?&locale=${lang}&populate=deep`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data) {
      setPageData(response.data.data.attributes);
    }
  }, [response]);

  if (loading) {
    return (
      <div className="container relative py-16">
        <Loading />
      </div>
    );
  }

  if (!pageData || error) {
    return;
  }

  const { list } = pageData;
  const textArray = list.map((item: any) => item.text);

  return (
    <div
      className={`text-[48px] lg:text-[84px] leading-none text-center z-10 ${className}`}
    >
      <Typewriter
        options={{
          strings: textArray,
          autoStart: true,
          loop: true,
        }}
      />
    </div>
  );
};

export default SectionTypewriter;
