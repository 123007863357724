import React from "react";
import { Link } from "react-router-dom";
import LogoSvg from "./LogoSvg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className = "w-[156px]" }) => {
  return (
    <Link
      to="/"
      className={`logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
      aria-label="UNITE Edelman Affiliate"
    >
      <LogoSvg />
    </Link>
  );
};

export default Logo;
