import Heading from "components/Heading/Heading";
import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import useWindowSize from "hooks/useWindowResize";
import useRequest from "hooks/useRequest";
import Loading from "shared/Loading/Loading";
import { CDN_BASE_URL } from "utils/constants";

export interface SectionValuesProps {
  className?: string;
  gridClassName?: string;
  lang: string;
}

const SectionValues: React.FC<SectionValuesProps> = ({
  className = "",
  lang,
}) => {
  const [pageData, setPageData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/value?&locale=${lang}&populate=deep`,
    method: "get",
  });

  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  useEffect(() => {
    if (response && response.data) {
      setPageData(response.data.data.attributes);
    }
  }, [response]);

  if (loading) {
    return (
      <div className="container relative py-16">
        <Loading />
      </div>
    );
  }

  if (!pageData || error) {
    return;
  }

  const { title, item } = pageData;

  return (
    <div className={`section-costumer-portfolio relative ${className}`}>
      <Heading
        headSignature
        desc=""
        className="container mb-12"
        headClassName="mb-0"
      >
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Heading>

      {WIN_WIDTH > 768 ? (
        <div className="md:container flex xl:grid grid-cols-4 gap-x-12 gap-y-16  overflow-y-hidden overflow-x-auto no-scrollbar">
          {item.map((item: any, i: number) => (
            <div
              className="values-box flex-shrink-0 w-fit first:pl-5 last:pr-5 md:first:pl-0 md:last:pr-0"
              key={i}
            >
              <div className="w-[282px]">
                <div
                  className={`relative rounded-full overflow-hidden signature-values ${item.textPosition}`}
                >
                  <span className="desc">{item.imageText}</span>
                  <img
                    src={CDN_BASE_URL + item.image.data.attributes.url}
                    alt={item.image.data.attributes.caption}
                    className="w-full"
                    loading="lazy"
                  />
                </div>
                <h5 className="text-3xl lg:text-4xl font-normal text-center mt-4 lg:mt-6 px-6">
                  {item.title}
                </h5>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Splide
          className="values-container"
          options={{
            type: "loop",
            drag: "free",
            focus: "center",
            arrows: false,
            pagination: false,
            perPage: 1.5,
            autoScroll: {
              pauseOnHover: false,
              pauseOnFocus: false,
              speed: 1,
            },
          }}
          extensions={{ AutoScroll }}
        >
          {item.map((item: any, i: number) => (
            <SplideSlide key={i}>
              <div className="values-box flex-shrink-0 w-fit">
                <div className="w-[282px]">
                  <div
                    className={`relative rounded-full overflow-hidden signature-values ${item.textPosition}`}
                  >
                    <span className="desc">{item.imageText}</span>
                    <img
                      src={CDN_BASE_URL + item.image.data.attributes.url}
                      alt={item.image.data.attributes.caption}
                      className="w-full"
                      loading="lazy"
                    />
                  </div>
                  <h5 className="text-3xl lg:text-4xl font-normal text-center mt-4 lg:mt-6 px-6">
                    {item.title}
                  </h5>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      )}
    </div>
  );
};

export default SectionValues;
