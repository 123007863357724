import React, { FC, useEffect, useState } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "components/SectionHero/SectionHero";
import "@splidejs/splide/dist/css/splide.min.css";
//
import useRequest from "hooks/useRequest";
import Loading from "shared/Loading/Loading";
import Page404 from "containers/Page404/Page404";
import PageLayout from "./PageLayout";

export interface SectionHeroProps {
  lang: string;
}

const PageHome: FC<SectionHeroProps> = ({ lang }) => {
  const [homeLayout, setHomeLayout] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/homepage?&locale=${lang}&populate=deep`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data) {
      setHomeLayout(response.data.data.attributes);
    }
  }, [response]);

  return (
    <div className="page-home relative overflow-hidden">
      <div className="relative mb-16 lg:mb-24 pt-[98px] md:pt-[120px]">
        <div className="container relative h-[calc(100svh-98px)] min-h-[450px] sm:min-h-[620px] md:min-h-[650px] lg:min-h-[530px] 2xl:min-h-[700px] max-h-[870px] flex">
          <BackgroundSection className="bg-white background-hero" />
          <SectionHero lang={lang} />
        </div>
        {loading ? (
          <div className="flex w-full justify-center">
            <Loading />
          </div>
        ) : !homeLayout || error ? (
          <Page404 />
        ) : (
          homeLayout.layout.map((item: any, i: number) => (
            <PageLayout item={item} lang={lang} key={i} />
          ))
        )}
      </div>
    </div>
  );
};

export default PageHome;
