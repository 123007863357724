import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Heading from "components/Heading/Heading";
//
import useRequest from "hooks/useRequest";
import Loading from "shared/Loading/Loading";
import { CDN_BASE_URL } from "utils/constants";

export interface SectionManifestoProps {
  className?: string;
  lang: string;
}

const SectionManifesto: FC<SectionManifestoProps> = ({
  className = "",
  lang,
}) => {
  const [t] = useTranslation("global");

  const [showFullText, setShowFullText] = useState(false);

  const [pageData, setPageData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/manifesto?&locale=${lang}&populate=deep`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data) {
      setPageData(response.data.data.attributes);
    }
  }, [response]);

  if (loading) {
    return (
      <div className="container relative py-16">
        <Loading />
      </div>
    );
  }

  if (!pageData || error) {
    return;
  }

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const { title, content, image, imageText } = pageData;

  const fullText = content;
  const displayedText = showFullText ? fullText : `${fullText.slice(0, 560)}`;

  return (
    <div
      className={`section-manifesto relative flex flex-col gap-10 lg:gap-16 text-white ${className}`}
    >
      <div className="text-center">
        <Heading
          isWhite
          isCenter
          headClassName="!mb-0 uppercase"
          desc=""
          className="mb-6 lg:mb-9"
        >
          {title}
        </Heading>

        <motion.div
          className="text-lg lg:text-2xl"
          layout
          transition={{
            type: "spring",
            stiffness: 700,
            damping: 30,
          }}
        >
          <div className="relative">
            <span
              className="content"
              dangerouslySetInnerHTML={{ __html: displayedText }}
            />
            <span
              className={`readMore ${showFullText ? "ml-0" : "ml-2"}`}
              onClick={toggleText}
            >
              {showFullText ? t("read-less") : t("read-more")}
            </span>
          </div>
        </motion.div>
      </div>
      <div className="flex-grow self-center flex flex-col items-center gap-3">
        <div className="custom-image lg:w-[540px]">
          <img
            src={CDN_BASE_URL + image.data.attributes.url}
            alt={image.data.attributes.caption || "UNITE Edelman"}
            loading="lazy"
          />
        </div>
        <p
          className="text-black text-xl lg:text-2xl max-w-[540px] text-balance text-center px-4 lg:px-7"
          dangerouslySetInnerHTML={{ __html: imageText }}
        />
      </div>
    </div>
  );
};

export default SectionManifesto;
