import React, { useLayoutEffect, useState } from "react";
import NavigationItem from "./NavigationItem";
//import { NAVIGATION } from "data/navigation";
import LangSwitch from "components/LangSwitch/LangSwitch";

export interface MenuBarProps {
  navigationData: any;
}
const Navigation: React.FC<MenuBarProps> = ({ navigationData }) => {
  const clamp = (value: number) => Math.max(0, value);
  const isBetween = (value: number, floor: number, ceil: number) =>
    value >= floor && value <= ceil;

  // hooks
  const useScrollspy = (ids: string[], offset: number = 0) => {
    const [activeId, setActiveId] = useState("");

    useLayoutEffect(() => {
      const listener = () => {
        const scroll = window.pageYOffset;

        const position = ids
          .map((id) => {
            const element = document.getElementById(id);

            if (!element) return { id, top: -1, bottom: -1 };

            const rect = element.getBoundingClientRect();
            const top = clamp(rect.top + scroll - offset);
            const bottom = clamp(rect.bottom + scroll - offset);

            return { id, top, bottom };
          })
          .find(({ top, bottom }) => isBetween(scroll, top, bottom));

        setActiveId(position?.id || "");
      };

      listener();

      window.addEventListener("resize", listener);
      window.addEventListener("scroll", listener);

      return () => {
        window.removeEventListener("resize", listener);
        window.removeEventListener("scroll", listener);
      };
    }, [ids, offset]);

    return activeId;
  };

  // const ids = [
  //   "manifestomuz",
  //   "faaliyet-alanlarimiz",
  //   "uzmanliklarimiz",
  //   "musteri-portfoyumuz",
  //   "basari-hikayelerimiz",
  //   "edelman",
  //   "insan-kultur",
  // ];

  const ids = navigationData.map((item: any) =>
    item.href.replace(/^\/?#?/, "")
  );

  const activeId = useScrollspy(ids, 125);

  return (
    <div className="flex mr-6">
      <ul className="navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
        {navigationData.map((item: any) => (
          <NavigationItem
            key={item.id}
            menuItem={item}
            activeUrl={"/#" + activeId}
          />
        ))}
      </ul>
      <LangSwitch />
    </div>
  );
};

export default Navigation;
