import React, { FC, Fragment, useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import StrategicArea from "./StrategicArea";
import CreativeArea from "./CreativeArea";
import useRequest from "hooks/useRequest";
import Loading from "shared/Loading/Loading";

export interface SectionExpertiseAreasProps {
  className?: string;
  gridClassName?: string;
  lang: string;
}

const SectionExpertiseAreas: FC<SectionExpertiseAreasProps> = ({
  className = "",
  gridClassName = "grid-cols-2",
  lang,
}) => {
  const [pageData, setPageData] = useState<any | null>(null);
  const { response, error, loading } = useRequest({
    url: `/multidisciplinary-service?&locale=${lang}&populate=deep`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data) {
      setPageData(response.data.data.attributes);
    }
  }, [response]);

  if (loading) {
    return (
      <div className="container relative py-16">
        <Loading />
      </div>
    );
  }

  if (!pageData || error) {
    return;
  }

  const {
    title,
    description,
    firstAreaTitle,
    firstAreaText,
    firstArea,
    secondAreaTitle,
    secondAreaText,
    secondArea,
  } = pageData;

  const strategicData = {
    firstAreaTitle,
    firstAreaText,
    firstArea,
  };

  const creativecData = {
    secondAreaTitle,
    secondAreaText,
    secondArea,
  };

  return (
    <div className={`section-expertise-areas relative ${className}`}>
      <Heading desc={description} isCenter isWhite className="mb-9 lg:mb-14">
        {title}
      </Heading>
      <div
        className={`max-w-full sm:max-w-[480px] h-[calc(100vw-34px)] sm:h-[478px] bg-cover grid mx-auto bg-expertise-btn bg-no-repeat bg-center items-center text-center ${gridClassName}`}
      >
        <StrategicArea data={strategicData} />
        <CreativeArea data={creativecData} />
      </div>
    </div>
  );
};

export default SectionExpertiseAreas;
